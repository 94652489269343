export const ImageCoverFactory = {
  init: () => {
    const components = document.querySelectorAll("[data-image-cover]") as NodeListOf<HTMLElement>;

    if (!components?.length) {
      return;
    }

    components.forEach((component) => {
      const collapsibleContent = component.querySelector("[data-image-cover-collapsible]") as HTMLImageElement;
      const contentContainer = component.querySelector("[data-image-cover-content]") as HTMLElement;

      if (!collapsibleContent || !contentContainer) {
        return;
      }

      component.onmouseover = () => {
        const contentHeight = contentContainer.clientHeight;

        collapsibleContent.style.height = `${contentHeight}px`;
        collapsibleContent.classList.remove("md:tw-opacity-0");
      };

      component.onmouseout = () => {
        collapsibleContent.style.height = "0px";
        collapsibleContent.classList.add("md:tw-opacity-0");
      };
    });
  }
};
