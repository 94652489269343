interface NavigationItem {
    linkElement: Element;
    itemContainer: Element;
    attachedCallbacks?: { eventName: string; listener: EventListener }[];
}

class Navigation {
    /** The outer header element */
    private header: Element | null = null;

    /** The outer container for all navigation items */
    private menu: Element | null = null;

    /** Menu toggle button */
    private menuButton: Element | null = null;

    /** All navigation items which has nested navigation items */
    private nestedItems: NavigationItem[] = [];

    private touchTriggerPosY: number | null = null;

    // holding variables for timers
    private menuEnterTimer: number | null = null;

    constructor(header: Element) {
        this.header = header;

        this.initialize = this.initialize.bind(this);

        this.attachEvents = this.attachEvents.bind(this);

        this.onFlyoutToggled = this.onFlyoutToggled.bind(this);
        this.onMenuToggled = this.onMenuToggled.bind(this);
        this.onNestedItemToggled = this.onNestedItemToggled.bind(this);

        this.isMobile = this.isMobile.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

        // Touch events to prevent swipes from triggering wrong events
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.onClickOutside = this.onClickOutside.bind(this);
        this.onClickInside = this.onClickInside.bind(this);

        this.initialize();
    }

    initialize() {
        if (!this.header) {
            console.error("Invalid header");
            return;
        }

        this.menu = this.header.querySelector(".l-header__menu");
        this.menuButton = this.header.querySelector(".l-header__menu-toggle");
        this.menuButton?.addEventListener("click", this.onMenuToggled);

        const navItems = this.header.querySelectorAll(".l-header__main-nav li");

        for (let i = 0; i < navItems.length; i++) {
            const item = navItems.item(i);

            if (!item) {
                continue;
            }

            let linkElement, itemContainer;

            for (let x = 0; x < item.children.length; x++) {
                const childItem = item?.children[x];

                if (!childItem) {
                    continue;
                }

                if (childItem.tagName.toLowerCase() === "a") {
                    linkElement = childItem;

                    if (!linkElement.classList.contains("s--level-0")) {
                        linkElement.setAttribute("tabindex", "-1");
                    }
                }

                itemContainer = item;
            }

            if (linkElement && itemContainer) {
                this.nestedItems.push({
                    linkElement,
                    itemContainer,
                });
            }
        }

        this.attachEvents();

        window.addEventListener("resize", this.attachEvents);
        document.addEventListener("click", this.onClickOutside);
        this.header.addEventListener("click", this.onClickInside);
    }

    attachEvents() {
        for (const item of this.nestedItems) {
            // Remove currently attached listeners
            if (item.attachedCallbacks) {
                for (const callback of item.attachedCallbacks) {
                    item.itemContainer.removeEventListener(callback.eventName, callback.listener);
                    item.linkElement.removeEventListener(callback.eventName, callback.listener);
                }
            }

            if (this.isMobile()) {
                // Set up event callback on mobile events
                item.attachedCallbacks = [
                    {
                        eventName: "touchstart", listener: this.onTouchStart
                    },
                    {
                        eventName: "touchend",
                        listener: (e: Event) => this.onTouchEnd(e, () => this.onNestedItemToggled(e, item)),
                    },
                    {
                        eventName: "click",
                        listener: (e: Event) => this.onNestedItemToggled(e, item, true),
                    }
                ];
            } else {
                // Set up event callback on desktop (mouse) events
                item.attachedCallbacks = [
                    {
                        eventName: "mouseenter",
                        listener: (e: Event) => this.onNestedItemToggled(e, item, true, true),
                    },
                    {
                        eventName: "mouseleave",
                        listener: (e: Event) => this.onNestedItemToggled(e, item, false, false),
                    },
                ];
            }

            // Attach new events
            if (item.attachedCallbacks) {
                for (const callback of item.attachedCallbacks) {
                    // To ensure proper logic and avoiding misfiring events,
                    // touch (mobile) event listeners will be attached the clicked link itself
                    // and mouse (desktop) event listeners will be attached to the container of the link,
                    const eventAttachmentTarget =
                        callback.eventName.indexOf("mouse") !== -1 ? "itemContainer" : "linkElement";

                    item[eventAttachmentTarget].addEventListener(callback.eventName, callback.listener);
                }
            }
        }

        for (const flyoutToggleElement of this.header?.querySelectorAll(
            "[data-flyout-selector]",
        ) as any) {
            // Remove, and re-attach listener
            flyoutToggleElement.removeEventListener("click", this.onFlyoutToggled);
            flyoutToggleElement.addEventListener("click", this.onFlyoutToggled);
        }
    }

    onFlyoutToggled(e: Event) {
        e.preventDefault();

        const clickTarget = e.currentTarget as Element;
        const targetFlyoutSelector = clickTarget?.getAttribute("data-flyout-selector");

        if (!targetFlyoutSelector || !targetFlyoutSelector.length) {
            return;
        }

        const targets =
            targetFlyoutSelector === "--parent"
                ? [clickTarget.parentElement]
                : this.header?.querySelectorAll(targetFlyoutSelector);

        if (!targets || !targets.length) {
            return;
        }

        if (clickTarget.classList.contains("s--active")) {
            clickTarget.classList.remove("s--active");
        } else {
            clickTarget.classList.add("s--active");
        }

        for (const target of targets as any) {
            if (target.classList.contains("s--active")) {
                target.classList.remove("s--active");
            } else {
                target.classList.add("s--active");
                target.querySelector("input").focus();
            }
        }
    }

    onMenuToggled(e: Event) {
        e.preventDefault();

        if (!this.menu) {
            return;
        }

        if (this.menu.classList.contains("s--active")) {
            this.menu.classList.remove("s--active");

            if (this.menuButton?.classList.contains("s--active")) {
                this.menuButton?.classList.remove("s--active");
            }
        } else {
            this.menu.classList.add("s--active");

            if (!this.menuButton?.classList.contains("s--active")) {
                this.menuButton?.classList.add("s--active");
            }
        }
    }

    onNestedItemToggled(e: Event, item: NavigationItem, forceState?: boolean, isMouseEnter?: boolean) {
        const that = this;
        let toggleBtnClicked = false;
        const showDelay = 400; // Delay in milliseconds

        if (that.isMobile()) {
            const hasSubNav = item.itemContainer.querySelector(".l-header__sub-nav") !== null;

            if (e.target instanceof HTMLElement) {
                if ((e.target as HTMLElement).classList.contains("s--toggle")) {
                    toggleBtnClicked = true;
                }
            }

            if (hasSubNav && toggleBtnClicked) {
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
            }
        }

        if (!that.isMobile() || (that.isMobile() && toggleBtnClicked)) {

            // If top level nav item, add delay for trigger
            if (item.linkElement.classList.contains("s--level-0")) {

                if (isMouseEnter) {
                    this.menuEnterTimer = setTimeout(() => {
                        that.toggleMenu(e, item, forceState); // Toggle to display
                    }, showDelay);
                } else {
                    // Clear the timeout on mouseleave
                    clearTimeout(this.menuEnterTimer);
                    that.toggleMenu(e, item, forceState); // Toggle to disappear                
                }

            }
            // If not top level nav item, display instantly
            else {
                that.toggleMenu(e, item, forceState);
            }
        }
    }

    toggleMenu(e: Event, item: NavigationItem, forceState?: boolean) {
        const shouldForceState = forceState !== undefined && forceState !== null;
        const isActive = item.itemContainer.classList.contains("s--active");
        const links = item.itemContainer.querySelectorAll("a");

        if (shouldForceState && isActive === forceState) {
            return;
        }

        if (isActive) {
            item.itemContainer.classList.remove("s--active");
            item.itemContainer.classList.add("s--deactivated");

            for (let i = 0; i < links.length; i++) {
                if (!links[i].classList.contains("s--level-0")) {
                    links[i].setAttribute("tabindex", "-1");
                }
            }

            setTimeout(() => {
                item.itemContainer.classList.remove("s--deactivated");
            }, 400);

        } else if (!isActive) {
            item.itemContainer.classList.add("s--active");

            const threshold = 50;
            const originalPosY = document.documentElement.scrollTop;

            for (let i = 0; i < links.length; i++) {
                links[i].setAttribute("tabindex", "0");
            }

            document.addEventListener("keyup", function (event) {
                if (event.key === "Escape") {
                    item.itemContainer.classList.remove("s--active");
                    item.itemContainer.classList.add("s--deactivated");

                    setTimeout(() => {
                        item.itemContainer.classList.remove("s--deactivated");
                    }, 300);
                }
            });

            window.addEventListener("scroll", (event) => {
                const scrollPosY = document.documentElement.scrollTop;

                if (scrollPosY - originalPosY > threshold) {
                    item.itemContainer.classList.remove("s--active");
                    item.itemContainer.classList.add("s--deactivated");

                    setTimeout(() => {
                        item.itemContainer.classList.remove("s--deactivated");
                    }, 300);
                }
            });
        }
    }

    /**
     * Checks if current viewport size corresponds to mobile,
     * to ensure we can implement different behaviors depending on if
     * mobile menu is active or not
     */
    isMobile() {
        const body = document.getElementsByTagName("body")[0];
        const viewportWidth =
            window.innerWidth ||
            (document.documentElement as HTMLElement).clientWidth ||
            body.clientWidth;

        return viewportWidth <= 1278;
    }

    onClickOutside(e: Event) {
        const activeElements = this.header?.querySelectorAll(".s--active");

        if (!activeElements) {
            return;
        }

        for (let i = 0; i < activeElements.length; i++) {
            const element = activeElements.item(i);

            element.classList.remove("s--active");
        }
    }

    onClickInside(e: Event) {
        e.stopPropagation();
    }

    onTouchStart(e: Event) {
        const touchEvent = e as TouchEvent;

        if (!touchEvent) {
            return;
        }

        this.touchTriggerPosY = touchEvent.changedTouches[0].clientY;
    }

    onTouchEnd(e: Event, callback: () => unknown) {
        const touchEvent = e as TouchEvent;

        if (!touchEvent || this.touchTriggerPosY === null) {
            return;
        }

        const touchEndPosY = touchEvent.changedTouches[0].clientY;
        const deltaY = this.touchTriggerPosY - touchEndPosY;

        // Compare to arbitrary number, whatever makes sense when testing
        if (Math.abs(deltaY) < 30) {
            this.touchTriggerPosY = null;
            callback();
        }
    }
}

export class NavigationFactory {
    static init() {
        const header = document.querySelector(".l-header");

        if (header) {
            const navigation = new Navigation(header);
        }
    }
}
