export const timer = 200;
export const breakpoint = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};
export const responsiveWidth = breakpoint.lg - 1;

export function isResponsive(): boolean {
    const x = window.matchMedia(`screen and (max-width:${responsiveWidth}px)`).matches;
    var isResponsive = false;

    if (x) {
        isResponsive = true;
    } else {
        isResponsive = false;
    }
    return isResponsive;
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const hasClass = (el: any, className: any) => el.classList.contains(className);
export const hasDataAttributeValue = (el: HTMLElement, dataAttribute: string, val: string) =>
    el.getAttribute(dataAttribute).split(",").indexOf(val) !== -1;
export const vw = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
export const vh = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export const nextUntil = (elm, selector, filter) => {
    var siblings = [];
    elm = elm.nextElementSibling;

    // as long as a sibling exists
    while (elm) {

        // if we've reached our match, quit
        if (elm.matches(selector)) break;

        // if filtering by a selector, check if the sibling matches
        if (filter && !elm.matches(filter)) {
            elm = elm.nextElementSibling;
            continue;
        }

        siblings.push(elm);

        elm = elm.nextElementSibling;
    }
    return siblings;
};

export const changeElementType: any = (elm: HTMLElement, newElmType: string) => {
    var newElm: HTMLElement = document.createElement(newElmType);

    // move children
    while (elm.firstChild) {
        newElm.appendChild(elm.firstChild);
    }

    // copy attributes
    for (let i: number = 0, a: Htmlattrbute = elm.attributes, l: number = a.length; i < l; i++) {
        newElm.setAttribute([a[i].name], a[i].value);
    }
    elm.parentNode.replaceChild(newElm, elm);
};

export const animate: any = (elem, style, unit, from, to, time, prop) => {
    if (!elem) {
        return;
    }
    var start = new Date().getTime(),
        timer = setInterval(function () {
            var step = Math.min(1, (new Date().getTime() - start) / time);
            if (prop) {
                elem[style] = (from + step * (to - from))+unit;
            } else {
                elem.style[style] = (from + step * (to - from))+unit;
            }
            if (step === 1) {
                clearInterval(timer);
            }
        }, 25);
    if (prop) {
          elem[style] = from+unit;
    } else {
          elem.style[style] = from+unit;
    }
}

export const docCookies = {
    getItem: function (sKey) {
        if (!sKey) { return null; }
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
    },
    removeItem: function (sKey, sPath, sDomain) {
        if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    },
    hasItem: function (sKey) {
        if (!sKey) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    },
    keys: function () {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
    }
};
// debounce & resize used like this:
// var resizeHandler = debounce(function () {
//     // resize triggered, do something
// }, timer, false);

// window.addEventListener('resize', resizeHandler);
