class Table {
  private table: Element | null = null;

  constructor(table: Element) {
    this.table = table;

    this.init();
  }

  attachTableRowClick(e: Event) {
    const clickTarget = e.currentTarget as HTMLElement;

    const url = clickTarget.dataset.url;

    if (!url) {
      return;
    }

    window.location.href = url;
  }

  init() {
    const newParent: HTMLElement = document.createElement("div");
    newParent.classList.add("table-responsive");

    this.table.parentNode.insertBefore(newParent, this.table);
    newParent.appendChild(this.table);

    const tableItems = this.table.querySelectorAll(".c-table__item");

    if (!tableItems) {
      return;
    }

    for (const item of tableItems as any) {
      item.addEventListener("click", this.attachTableRowClick);
    }
  }
}

export class TableFactory {
  static init() {
    const tables = document.querySelectorAll(".c-table");

    for (const table of tables as any) {
      const t = new Table(table);
    }
  }
}
