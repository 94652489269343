import { timer, debounce, docCookies } from "../helpers/Helper";

(() => {
    const componentName = "c-cookie-popup";
    const component: HTMLElement = document.querySelector(`.js-${componentName}`);

    if (component != null) {
        const cookieOkBtn: HTMLElement = component.querySelector(".btn--accept");
        const acceptCookieName = "accept-cookies";
        const isVisibleCssClassName = "is-visible";

        const cookieAccepted = (e: Event) => {
            e.preventDefault();
            const now = new Date();
            now.setTime(now.getTime() + 90 * (24 * 60 * 60 * 1000)); // 90 days

            docCookies.setItem(acceptCookieName, true, now, "/", "", "");
            component.classList.remove(isVisibleCssClassName);
            component.setAttribute("aria-hidden", "true");
        };

        const init = () => {
            const acceptCookie = docCookies.getItem(acceptCookieName);
            if (acceptCookie === null) {
                var timer = setTimeout(function () {
                    component.classList.add(isVisibleCssClassName);
                    component.setAttribute("aria-hidden", "false");
                    clearTimeout(timer);
                    timer = null;
                }, 2000);
            }
            cookieOkBtn.addEventListener("click", cookieAccepted);
        };

        init();
    }
})();
