(() => {
    const componentName = "c-blockquote__editor";
    const components = document.querySelectorAll(`.${componentName}`);

    function init() {
        for (let i = 0; i < components.length; i++) {
            const component = components[i];

            component.innerHTML = "<blockquote class='c-blockquote'><p>" + component.innerHTML + "</p></blockquote>"
        }
    }

    init();
})();
