export const FaqBlockFactory = {
  init: () => {
    const blocks = document.querySelectorAll("[data-faq]") as NodeListOf<HTMLElement>;

    // If there are no faq blocks, or only one, return.
    // Single FAQ items should be expanded on page load.
    if (!blocks?.length || blocks.length === 1) {
      return;
    }

    blocks.forEach((currentBlock) => {
      if (currentBlock.getAttribute("data-faq") === "disabled") {
        return;
      }

      let isOpen = false;

      const toggleButton = currentBlock.querySelector("[data-faq-toggle]") as HTMLElement;
      const answerContent = currentBlock.querySelector("[data-faq-answer]") as HTMLElement;
      const answerInnerContainer = answerContent?.querySelector("[data-faq-answer-inner]") as HTMLElement;
      
      if (!toggleButton || !answerContent || !answerInnerContainer) {
        return;
      }

      toggleButton.onclick = () => {
        isOpen = !isOpen;
        toggleCssClasses(currentBlock, isOpen);
      };
    });
  }
};

const toggleCssClasses = (currentBlock: HTMLElement, isOpening: boolean) => {
  // Get all elements with classes specified for opening and closing state
  const elements = currentBlock.querySelectorAll("[data-faq-open-class]") as NodeListOf<HTMLElement>;

  elements.forEach(element => {
    const closedClass = element.getAttribute("data-faq-closed-class");
    const openClass = element.getAttribute("data-faq-open-class");

    if (isOpening) {
      element.classList.remove(closedClass);
      element.classList.add(openClass);
    } else {
      element.classList.remove(openClass);
      element.classList.add(closedClass);
    }
  });
}
