class LanguagePicker {
  private languagePicker: Element | null = null;
  private languagePickerBtn: HTMLElement | null = null;

  constructor(langPicker: Element) {
    this.languagePicker = langPicker;
    this.onMenuToggled = this.onMenuToggled.bind(this);

    this.init();
  }

  init() {
    this.languagePickerBtn = this.languagePicker.querySelector(".c-langpicker__btn");
    this.languagePickerBtn?.addEventListener("click", this.onMenuToggled);

    if (this.languagePickerBtn) {
      const activeLanguage = this.languagePicker.querySelector(
        ".c-langpicker__list-item.s--active",
      );

      if (activeLanguage) {
        const link = activeLanguage.querySelector("a");
        const langName = link.innerHTML;

        const childNodes = this.languagePickerBtn.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
          const node = childNodes.item(i);
          if (node.nodeName === "#text") {
            //node.nodeValue = langName;
            break;
          }
        }
      }
    }
  }

  onMenuToggled(e: Event) {
    e.preventDefault();

    if (!this.languagePicker) {
      return;
    }

    if (this.languagePicker.classList.contains("s--active")) {
      if (this.languagePicker.classList.contains("c-langpicker")) {
        this.languagePicker.classList.remove("s--active");
      }

      if (this.languagePickerBtn?.classList.contains("s--active")) {
        this.languagePickerBtn?.classList.remove("s--active");
      }
    } else {
      this.languagePicker.classList.add("s--active");

      if (!this.languagePickerBtn?.classList.contains("s--active")) {
        this.languagePickerBtn?.classList.add("s--active");
      }
    }
  }
}

export class LanguagePickerFactory {
  static init() {
    const header = document.querySelector(".l-header");

    if (header) {
      const langPicker = header.querySelector(".c-langpicker");
      if (langPicker) {
        const picker = new LanguagePicker(langPicker);
      }
    }
  }
}
