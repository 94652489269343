import * as React from "react";
import ReactPlayer from "react-player";
import { VideoProps } from "../interfaces/VideoProps";

export default class VideoPlayer extends React.Component<VideoProps> {
  constructor(props: VideoProps) {
    super(props);
  }
  render() {
    const props: VideoProps = this.props;
    const height = props.height ?? "100%";
    const width = props.width ?? "100%";

    return (
      <ReactPlayer
        className="react-player"
        url={props.src}
        {...props.attributes}
        width={width}
        height={height}
      />
    );
  }
}

// ReactPlayer: https://www.npmjs.com/package/react-player
