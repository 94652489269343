import { hasClass } from "../helpers/Helper";

class Dropdown {
  private dropdown: HTMLSelectElement | null = null;
  private iconContainer: Element | null = null;
  private icon: Element | null = null;

  constructor(dropdown: HTMLSelectElement) {
    this.dropdown = dropdown;
    this.iconContainer = dropdown.parentElement.querySelector(".c-select__icon");
    this.icon = this.iconContainer?.querySelector(".chevron");
    this.init();
  }

  init() {
    this.dropdown.addEventListener("change", this.attachChangeEvent);

    if (this.iconContainer && this.icon) {
      this.dropdown.addEventListener("click", (e: Event) => {
        if (this.iconContainer.classList.contains("down")) {
          this.iconContainer.classList.remove("down");
          this.icon.classList.add("bottom");
        } else {
          this.iconContainer.classList.add("down");
          this.icon.classList.remove("bottom");
        }
      });
    }

    this.setSelectedOption();
    document.addEventListener("click", this.onClickOutside);
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        this.close();
      }
    });
  }

  attachChangeEvent(e: Event) {
    e.stopPropagation();

    const changeTarget = e.currentTarget as HTMLSelectElement;
    const selectedIndex = changeTarget.selectedIndex;
    const selectedOption = changeTarget.options[selectedIndex];

    if (selectedOption.value && selectedOption.value !== "") {
      window.location.href = selectedOption.value;
    }
  }

  setSelectedOption() {
    const params = new URLSearchParams(window.location.search);
    const filter = params.get("filter");

    if (filter) {
      for (const option of this.dropdown.options as any) {
        if (option.value && option.value.indexOf(`filter=${filter}`) !== -1) {
          option.selected = true;
        }
      }
    }
  }

  onClickOutside(e: Event) {
    e.stopPropagation();
    const expandedSelects = document.querySelectorAll(".c-select__icon.down");
    const target = e.target as HTMLElement;

    if (!expandedSelects) {
      return;
    }

    if (!hasClass(target, "c-select")) {
      for (let i = 0; i < expandedSelects.length; i++) {
        const iconContainer = expandedSelects.item(i);
        const icon = iconContainer.querySelector(".icon");
        iconContainer.classList.remove("down");
        icon.classList.add("bottom");
      }
    }
  }

  close() {
    const expandedSelects = document.querySelectorAll(".c-select__icon.down");

    if (!expandedSelects) {
      return;
    }

    for (let i = 0; i < expandedSelects.length; i++) {
      const iconContainer = expandedSelects.item(i);
      const icon = iconContainer.querySelector(".icon");
      iconContainer.classList.remove("down");
      icon.classList.add("bottom");
    }
  }
}

export class DropdownFactory {
  static init() {
    const dropdowns = document.querySelectorAll(".c-select");

    for (const dropdown of dropdowns as any) {
      const t = new Dropdown(dropdown);
    }
  }
}
