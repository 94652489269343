import { hasClass } from "../helpers/Helper";

class ImageBank {
    private imageBank: Element | null = null;
    private toggleButtons: NodeListOf<Element> | null = null;
    private cardBodies: NodeListOf<Element> | null = null;
    private searchInput: Element | null = null;

    constructor(imageBank: Element) {
        this.imageBank = imageBank;
        this.toggleButtons = imageBank.querySelectorAll(".c-image-bank__toggle");
        this.cardBodies = imageBank.querySelectorAll(".card-body");
        this.searchInput = imageBank.querySelector(".c-filter__search");
        this.init();
    }

    attachToggleButtonClickEvent(e: Event) {
        const btn = e.currentTarget as HTMLElement;
        const isActive = hasClass(btn, "t--active");

        this.resetCards();

        const icon = btn.querySelector(".c-icon");
        const card = btn.parentElement;
        const img = card.querySelector(".card-img");
        const show = card.querySelector(".c-icon-label") as HTMLElement;
        const cardBody = card.querySelector(".card-body") as HTMLElement;
        const offsetTop = btn.offsetTop;

        if (!isActive) {
            if (img) {
                img.classList.add("t--active");
            }
            btn.classList.add("t--active");
            icon.classList.add("t--x");
            cardBody.classList.remove("d-none");

            // Switch label
            show.innerText = show.dataset.label;

            const btnHeight = btn.offsetHeight;
            const cardHeight = cardBody.offsetHeight;
            const cardTop = offsetTop + btnHeight;

            card.style.marginBottom = `calc(${cardHeight}px + 3rem)`;
            cardBody.style.top = `${cardTop}px`;

            const inactiveCards = this.imageBank.querySelectorAll(".card-img:not(.t--active)");

            for (const imageCard of inactiveCards as any) {
                imageCard.classList.add("t--opacity");
            }
        } else {
            card.classList.remove("t--active");
            btn.classList.remove("t--active");
            icon.classList.remove("t--x");
            cardBody.classList.add("d-none");
            card.style.marginBottom = "0";

            // Switch label
            show.innerText = show.dataset.moreLabel;

            const inactiveCards = this.imageBank.querySelectorAll(".card-img:not(.t--active)");

            for (const imageCard of inactiveCards as any) {
                imageCard.classList.remove("t--opacity");
            }
        }
    }

    attachSearchInputChangeEvent(e: Event) {
        const input = e.currentTarget as HTMLElement;
        const term = (<HTMLInputElement>document.getElementById(input.id)).value;

        var searchResultItems = this.imageBank.querySelectorAll(".c-image-bank__item");
        var noHitsText = this.imageBank.querySelector(".c-image-list-no-hits");

        noHitsText.classList.remove("c-image-list-no-hits__show");

        var counter = 0;

        for (const item of searchResultItems as any) {
            const heading = item.querySelector(".card__subtitle").innerText;
            const body = item.querySelector(".card-body").innerText;

            if (heading.toLowerCase().includes(term.toLocaleLowerCase()) || body.toLowerCase().includes(term.toLocaleLowerCase())) {
                item.parentElement.classList.remove("c-image-bank__item-wrapper-hide");
                counter = counter + 1;
            } else {
                item.parentElement.classList.add("c-image-bank__item-wrapper-hide");
            }
        }

        if (counter == 0) {
           noHitsText.classList.add("c-image-list-no-hits__show");
        }
    }

    resetCards() {
        for (const btn of this.toggleButtons as any) {
            btn.classList.remove("t--active");
            const card = btn.parentElement;
            const icon = btn.querySelector(".c-icon");
            const img = card.querySelector(".card-img");
            card.style.marginBottom = 0;
            icon.classList.remove("t--x");
            if (img != null) {
                img.classList.remove("t--active");
                img.classList.remove("t--opacity");
            }
        }
        for (const cardBody of this.cardBodies as any) {
            cardBody.classList.add("d-none");
        }
    }

    setupToggleButtons() {
        if (!this.toggleButtons) {
            console.warn("No toggle buttons present");
            return;
        }

        for (const btn of this.toggleButtons as any) {
            btn.addEventListener("click", (e: Event) => {
                this.attachToggleButtonClickEvent(e);
            });
        }
    }

    setupSearchInput() {
        if (!this.searchInput) {
            console.warn("No search input present");
            return;
        }

        this.searchInput.addEventListener("change", (e: Event) => {
            this.attachSearchInputChangeEvent(e);
        });

        this.searchInput.addEventListener("keyup", (e: Event) => {
            this.attachSearchInputChangeEvent(e);
        });

        this.searchInput.addEventListener("paste", (e: Event) => {
            this.attachSearchInputChangeEvent(e);
        });
    }

    init() {
        this.setupToggleButtons();

        document.addEventListener("keyup", (e) => {
            if (e.key === "Escape" || e.key === "Esc") {
                this.resetCards();
            }
        });

        this.setupSearchInput();
    }
}

export class ImageBankFactory {
    static init() {
        const imageBanks = document.querySelectorAll(".c-image-bank");

        for (const imageBank of imageBanks as any) {
            const t = new ImageBank(imageBank);
        }
    }
}
