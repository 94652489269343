﻿interface NavigationItem {
    toggleElement: Element;
    itemContainer: Element;
    attachedCallbacks?: { eventName: string; listener: EventListener }[];
}

class LeftNavigation {
    /** The outer header element */
    private leftNavigation: Element | null = null;

    /** All navigation items which has nested navigation items */
    private subItems: NavigationItem[] = [];

    constructor(leftNavigation: Element) {
        this.leftNavigation = leftNavigation;
        this.initialize = this.initialize.bind(this);
        this.attachEvents = this.attachEvents.bind(this);
        this.onNestedItemToggled = this.onNestedItemToggled.bind(this);
        this.initialize();
    }

    initialize() {
        if (!this.leftNavigation) {
            console.error("Invalid left navigation");
            return;
        }

        const leftNavItems = this.leftNavigation.querySelectorAll(".c-leftmenu__nav-item");

        for (let i = 0; i < leftNavItems.length; i++) {
            const item = leftNavItems.item(i);

            if (!item) {
                continue;
            }

            let toggleElement, itemContainer;

            for (let x = 0; x < item.children.length; x++) {
                const childItem = item?.children[x];

                if (!childItem) {
                    continue;
                }

                if (childItem.tagName.toLowerCase() === "button") {
                    toggleElement = childItem;
                }

                itemContainer = item;
            }

            if (toggleElement && itemContainer) {
                this.subItems.push({
                    toggleElement,
                    itemContainer,
                });
            }
        }

        this.attachEvents();

        window.addEventListener("resize", this.attachEvents);
    }

    attachEvents() {
        for (const item of this.subItems) {
            // Remove currently attached listeners
            if (item.attachedCallbacks) {
                for (const callback of item.attachedCallbacks) {
                    item.itemContainer.removeEventListener(callback.eventName, callback.listener);
                    item.toggleElement.removeEventListener(callback.eventName, callback.listener);
                }
            }

            // Set up event callback on desktop (mouse) events
            item.attachedCallbacks = [
                {
                    eventName: "click",
                    listener: (e: Event) => this.onNestedItemToggled(e, item, true),
                }
            ];

            // Attach new events
            if (item.attachedCallbacks) {
                for (const callback of item.attachedCallbacks) {
                    // To ensure proper logic and avoiding misfiring events,
                    // touch (mobile) event listeners will be attached the clicked link itself
                    // and mouse (desktop) event listeners will be attached to the container of the link,
                    const eventAttachmentTarget =
                        callback.eventName.indexOf("mouse") !== -1 ? "itemContainer" : "toggleElement";

                    item[eventAttachmentTarget].addEventListener(callback.eventName, callback.listener);
                }
            }
        }
    }

    onNestedItemToggled(e: Event, item: NavigationItem, forceState?: boolean) {
        let toggleBtnClicked = false;
        let elem;

        const hasSubNav = item.itemContainer.querySelector(".c-leftmenu__nav-sub") !== null;

        if (e.currentTarget instanceof HTMLElement) {
            elem = e.currentTarget as HTMLElement;
            if (elem.classList.contains("s--toggle")) {
                toggleBtnClicked = true;
            }
        }

        if (hasSubNav && toggleBtnClicked) {
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
        }

        if (toggleBtnClicked) {
            const isActive = item.itemContainer.classList.contains("s--expanded");

            if (isActive) {
                item.itemContainer.classList.remove("s--expanded");
            } else if (!isActive) {
                item.itemContainer.classList.add("s--expanded");
            }
        }
    }
}

export class LeftNavigationFactory {
    static init() {
        const leftNavigation = document.querySelector(".c-leftmenu");

        if (leftNavigation) {
            const leftNav = new LeftNavigation(leftNavigation);
        }
    }
}