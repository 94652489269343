import { NavigationFactory } from "./js/components/Navigation";
import { LeftNavigationFactory } from "./js/components/LeftNavigation";
import { LanguagePickerFactory } from "./js/components/LanguagePicker";
import { TableFactory } from "./js/components/Table";
import { ImageBankFactory } from "./js/components/ImageBank";
import { DropdownFactory } from "./js/components/Dropdown";
import { FaqBlockFactory } from "../Features/Blocks/Faq/faqBlock";
import { ImageCoverFactory } from "../Features/Blocks/PromotedImageCovers/image-cover";

import "./js/helpers/Helper";
import "./js/components/c-cookie-popup";
import "./js/components/c-blockquote";

import "./sass/index.scss";
import "./sass/editor.scss";

import * as React from "react";
import ReactDOM from "react-dom";

import VideoPlayer from "./js/components/VideoPlayer";

const componentTypes: { [index: string]: any } = {
  VideoPlayer,
};

const componentIdentifier = "data-react-component";
const header: HTMLElement = document.querySelector(".l-header");
const scrollHeightCutoff = 30;
const scrolledCssClass = "l-header--scrolled";

const includeHTML = () => {
  const elements = document.getElementsByTagName("*");
  for (const elm of elements as any) {
    if (elm.hasAttribute && elm.hasAttribute("data-include")) {
      fragment(elm, elm.getAttribute("data-include"));
    }
  }
  function fragment(el: any, url: any) {
    const localTest = /^(?:file):/;
    const xmlhttp = new XMLHttpRequest();
    let status = 0;

    xmlhttp.onreadystatechange = () => {
      /* if we are on a local protocol, and we have response text, we'll assume
       *  				things were sucessful */
      if (xmlhttp.readyState === 4) {
        status = xmlhttp.status;
      }
      if (localTest.test(location.href) && xmlhttp.responseText) {
        status = 200;
      }
      if (xmlhttp.readyState === 4 && status === 200) {
        el.outerHTML = xmlhttp.responseText;
      }
    };

    try {
      xmlhttp.open("GET", url, true);
      xmlhttp.send();
    } catch (err) {
      /* todo catch error */
    }
  }
};

const onDOMLoaded = (event: Event) => {
  document.getElementsByTagName("html")[0].style.visibility = "visible";

  const initialize = (componentContainer: Element) => {
    const props = JSON.parse(componentContainer.getAttribute("data-props") || "{}");

    ReactDOM.render(
      React.createElement(
        componentTypes[componentContainer.getAttribute(componentIdentifier) || ""],
        props,
      ),
      componentContainer,
    );
  };

  // Mount React components
  const nodes = document.querySelectorAll("[" + componentIdentifier + "]");

  for (const node of nodes as any) {
    // cast to "any" as NodeListOf<> yields "... is not an array type" error
    initialize(node);
  }
  includeHTML();
};

const onScroll = (event: Event) => {
  if (header.classList.contains("l-header--no-scroll")) {
    return;
  }

  const scrollHeight = window.pageYOffset;

  if (scrollHeight > scrollHeightCutoff) {
    header.classList.add(scrolledCssClass);
  } else {
    header.classList.remove(scrolledCssClass);
  }
};

NavigationFactory.init();
LeftNavigationFactory.init();
LanguagePickerFactory.init();
TableFactory.init();
ImageBankFactory.init();
DropdownFactory.init();

FaqBlockFactory.init();
ImageCoverFactory.init();

document.addEventListener("DOMContentLoaded", onDOMLoaded);
document.addEventListener("scroll", onScroll);
